.Link {
  color: var(--text-primary); /*Link text color*/
  margin-bottom: 0;
}
.ActiveLink {
  color: var(--text-info); /*Current link text color*/
  font-weight: bold;
}

.Link::before{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  background-color: var(--text-info); /*Hover link underline text color*/
  color: var(--text-extra);
  transition: all 400ms;
}
.ActiveLink::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  background-color: var(--text-primary);
  transition: all 400ms;
}

.Link:hover::before,
.Link:active::before {
  transform: scale(1);
  transition: all 400ms;
  opacity: 1;
}

@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  font-family: "Lato", sans-serif;
  scroll-behavior: smooth;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  overflow: hidden;
}

:root {
  --background-primary: #0A192F;
  --background-secondary: #0A192F;
  --background-accent: #b4cee6;
  --background-hover: #10293B;
  --text-primary: #CCD6F6;
  --text-secondary: #8892B0;
  --text-info: #64ffda;
  --text-2: #172A45;
  --text-3: #A8B2D1;
  --text-header: #172A45;
  --text-extra: #ff0000;
}

/* accent = tags */
/* text-primary = tag color and navbar selected color */
/* text-secondary = navbar color, main head color(education), details of exp & projects only */
/* bg-primary = background color only */
/* bg-secondary = in mobile view bg color */

*::-webkit-scrollbar-thumb {
  display: none;
}
*::-webkit-scrollbar {
  display: none;
}

*::selection {
  background: var(--text-secondary);
  color: var(--background-primary);
}

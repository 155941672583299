.Tag {
  background: var(--text-primary); /* bg color*/
  border-radius: 3px 0 0 3px;
  font-weight: 600;
  color: var(--background-primary); /* text color*/
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  transition: color 0.2s;
  cursor: default;
}

.Tag::before {
  background: var(--background-primary); /*dot color*/
  border-radius: 8px;
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.Tag::after { /* arrow design */
  background: var(--background-primary);
  border-bottom: 13px solid var(--background-primary);
  border-left: 10px solid var(--text-primary); /* arrow color */
  border-top: 13px solid var(--background-primary);
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.Tag:hover {
  background-color: var(--text-info); /*hover bg color*/
  color: var(--background-primary); /*hover text color*/
}
.Tag:hover::before {
  background-color: var(--background-primary); /*hover dot color*/
  color: var(--background-primary);
}

.Tag:hover::after {
  border-left-color: var(--text-info); /*hover arrow color*/
}
